<template>
    <div class="PriceCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            :rules="rules"
            ref="createForm"
            label-width="80px"
            size="small"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row style="margin-top: 16px">
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model="search" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="queryAndAdd" size="small" :disabled="disableQueryAndAddButton"
                    >查询并添加
                </el-button>
                <el-button type="primary" @click="showPickUpGoods" size="small" :disabled="disableShowPickUpGoodsDialog"
                    >选择商品基本信息
                </el-button>
                <el-button type="primary" @click="handleSave" size="small" :disabled="disableSaveButton"
                    >保存
                </el-button>
                <el-button type="primary" @click="importFile" size="small" :disabled="!form.deptCode">导入</el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品编码" width="220" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.goodsCode'"
                                :rules="rules.goodsCode"
                            >
                                {{ scope.row.goodsCode }}
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称" width="250">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="出厂价" v-if="hasPrivilege('biz.price.factory.query')" width="200">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showFactoryPrices'"
                                :rules="deptType === 2 ? rules.showFactoryPrices : rules.showFactoryPrices2"
                            >
                                <ef-price-input v-model="scope.row.showFactoryPrices" :precision="3" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="批发价" width="200" v-if="hasPrivilege('biz.price.wholeSale.query')">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showWholeSalePrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input v-model="scope.row.showWholeSalePrices" :precision="2" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="零售价" width="200" v-if="hasPrivilege('biz.price.retail.query')">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showRetailPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input v-model="scope.row.showRetailPrices" :precision="2" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="计划成本" width="200" v-if="hasPrivilege('biz.price.plan.edit')">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showPlanCostPrices'"
                                :rules="rules.showPlanCostPrices"
                            >
                                <ef-price-input v-model="scope.row.showPlanCostPrices" :precision="4" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pick-up-goods ref="pickupGoods" @queryGoods="setSelectedGoods" />
        <el-dialog title="请选择操作，同时只能上传一个文件" :visible.sync="imflag" width="30%">
            <span>
                <el-upload
                    ref="sysInUploader"
                    style="text-align: left; display: inline-block; margin-left: 15px"
                    :action="this.$proxyApiHostUrlSuffix + '/goods/price/importExcel'"
                    accept=".xls"
                    :on-success="upSuccess"
                    :data="{ deptCode: form.deptCode }"
                    :multiple="false"
                    :show-file-list="false"
                >
                    <el-button type="primary">导 入</el-button>
                </el-upload>
                <el-button style="margin-left: 10px" type="primary" @click="getTemplate">下 载 模 板</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import MoneyUtils from 'js/MoneyUtils';
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import StockBizCommon, {
    factoryPrice,
    planCostPrice,
    PropertyConverter,
    retailPrice,
    wholeSalePrice,
} from 'js/StockBizCommon';
import EfPriceInput from 'components/EfPriceInput';
import { deptCodeValidateRule, goodsCodeValidateRule, pricesValidateRule } from 'js/validate/ValidateCommonRule';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'PriceCreate',
    components: { PickUpGoods, EfDeptTypeAndDeptSelect, EfPriceInput },
    data() {
        return {
            search: '',
            deptType: null,
            imflag: false,
            form: {
                deptCode: '',
                deptName: '',
                detailParams: [],
            },
            goodsDetailMap: new Map(),
            rules: {
                deptCode: deptCodeValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                showPrices: [
                    { type: 'number', required: true, min: 0.01, message: '必须大于0', trigger: 'blur' },
                    pricesValidateRule(2),
                ],
                showFactoryPrices: [
                    { type: 'number', required: true, min: 0.001, message: '必须大于0', trigger: 'blur' },
                    pricesValidateRule(3),
                ],
                showFactoryPrices2: [
                    { type: 'number', required: false, min: 0.001, message: '必须大于0', trigger: 'blur' },
                ],
                showPlanCostPrices: [
                    { type: 'number', required: true, min: 0.0001, message: '必须大于0', trigger: 'blur' },
                    pricesValidateRule(4),
                ],
            },
        };
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            return Util.isEmpty(this.form.deptCode) || Util.isEmpty(this.search);
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.deptCode);
        },
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.deptCode) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
        showFactoryPriceFlag() {
            return this.deptType === 2;
        },
    },
    watch: {
        'form.deptCode': function (newDeptCode, oldDeptCode) {
            //清空商品列表数据
            this.form.detailParams = [];
        },
    },
    methods: {
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
        },
        setDept(deptCode, deptName, deptType) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
            this.deptType = deptType;
        },
        setDeptType(deptType) {
            this.deptType = deptType;
        },
        setSelectedGoods(selectedGoods, imp) {
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                this.form.detailParams,
                this.detailParamPropertyArrForAdd(imp),
                imp
            );
        },
        showPickUpGoods() {
            this.$refs.pickupGoods.show({ deptCode: this.form.deptCode }, this.form.deptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ deptCode: this.form.deptCode, search: this.search });
            this.setSelectedGoods(rst.data);
        },
        handleSave() {
            this.$refs.createForm.validate((valid) => {
                if (!valid) {
                    return false;
                }

                const param = {
                    deptCode: this.form.deptCode,
                    goodsCodes: this.form.detailParams.map((r) => r.goodsCode),
                    factoryPrices: this.form.detailParams.map((r) => MoneyUtils.moneyToDb(r.showFactoryPrices) || ' '),
                    retailPrices: this.form.detailParams.map((r) => MoneyUtils.moneyToDb(r.showRetailPrices)),
                    planCostPrices: this.form.detailParams.map((r) => MoneyUtils.moneyToDb(r.showPlanCostPrices)),
                    wholeSalePrices: this.form.detailParams.map((r) => MoneyUtils.moneyToDb(r.showWholeSalePrices)),
                };
                UrlUtils.PostRemote(this, '/goods/price/createExtend', param, null, () => {
                    this.goBackAndReload();
                    this.$message.success('保存成功');
                });
            });
        },
        importFile() {
            this.imflag = true;
        },
        upSuccess(response, file, fileList) {
            if (response.status === 7001) {
                this.$message.error(response.message);
                return;
            }
            if (response.status === 200) {
                this.imflag = false;
                this.setSelectedGoods(response.data, true);
            }
        },
        getTemplate() {
            UrlUtils.Export(this, '模板', '/goods/price/downloadTemplate', {});
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        detailParamPropertyArrForAdd(imp) {
            const arr = [
                'goodsCode',
                new PropertyConverter(retailPrice, 'showRetailPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
                new PropertyConverter(wholeSalePrice, 'showWholeSalePrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
            ];
            if (this.showFactoryPriceFlag || imp) {
                arr.push(
                    new PropertyConverter(factoryPrice, 'showFactoryPrices', (price) => {
                        return MoneyUtils.moneyToThree(price);
                    })
                );
            }
            if (this.hasPrivilege('biz.price.plan.edit')) {
                arr.push(
                    new PropertyConverter(planCostPrice, 'showPlanCostPrices', (price) => {
                        return MoneyUtils.moneyToFour(price);
                    })
                );
            }
            return arr;
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
